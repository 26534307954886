$nav-height: 60px;

// text styles
$default-text-color: #fff;
$default-link-color: rgb(109, 224, 135);

// background colors
$default-bg-color: rgb(25 26 26);
$default-header-bg-color: rgb(56, 58, 60);
$transparent-bg-color: rgba(0, 0, 0, 0.8);

// border colors
$default-border-color: #ccc;