@import '../../../styles/variables';

.flight-booking-widget-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $default-bg-color;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.flight-booking-widget {
  width: 90%;
  height: 90%;
  min-width: 500px;
  min-height: 600px;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 10px;
}

.flight-booking-widget-portable {
  width: 350px;
  height: 600px;
  border: none;
  border-radius: 10px;
}