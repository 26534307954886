@import '../../../styles/variables';

.stay-search-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $default-bg-color;
  width: 100%;
  height: 100%;
  min-width: 400px;
  min-height: 600px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.stay-widget-iframe {
  width: 90%;
  height: 90%;
  border: none;
  border-radius: 10px;
}

.stay-widget-iframe-portable {
  width: 350px;
  height: 600px;
  border: none;
  border-radius: 10px;
}
