@import '../../styles/variables.scss';

.app-container {
  max-height: calc(100vh - 60px);
  background-color: $transparent-bg-color;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
}

.app-container-portable {
  background-color: $transparent-bg-color;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
}

.app-sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 150px;
  max-width: 400px;
  display: none;
  flex-direction: row;
  background-color: $transparent-bg-color;
  box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25);
  z-index: 5;
}

.app-sidebar-visible {
  display: flex;
}

.app-sidebar-portable {
  flex-grow: 0;
  flex-shrink: 0;
  height: 50%;
  max-height: calc(100vh - 20%);
  min-height: 70px;
  display: flex;
  flex-direction: column-reverse;
  background-color: $transparent-bg-color;
  box-shadow: 0 -8px 22px -7px rgba(0, 0, 0, 0.25);
  z-index: 5;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #c1c3c5b4 #e9e9e9;
}

.app-sidebar-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.app-sidebar-header {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  padding: 5px 5px 0 0;
}

.app-sidebar-header-spot-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 0 25px;
}

.close-btn {
  align-self: flex-end;
  background: none;
  color: #e9e9e9;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.app-sidebar-resizer-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 5px;
  cursor: col-resize;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 3px;
}

.app-sidebar-resizer {
  background: $default-header-bg-color;
  height: 50px;
  width: 100%;
  border-radius: 25px;
}

.app-sidebar-resizer-wrapper-portable {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20px;
  cursor: row-resize;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-sidebar-resizer-portable {
  background: $default-header-bg-color;
  height: 50%;
  width: 70px;
  border-radius: 25px;
  margin: 5px;
}

.app-sidebar-resizer:hover {
  background: #c1c3c5b4;
}

.pois-list-heading {
  align-items: center;
  padding: 0 15px;
}

.pois-list-heading-title {
  color: #e9e9e9;
  width: 100%;
  text-align: center;
  margin: 15px 0;
}

.pois-list {
  overflow-y: auto;
  padding: 0;
  scrollbar-width: none; // For Firefox
  -ms-overflow-style: none; // For Internet Explorer and Edge'
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.pois-list-body {
  max-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; // For Firefox
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; // For Internet Explorer and Edge
}

.pois-list::-webkit-scrollbar {
  display: none; // For Chrome, Safari, and Opera
}

.pois-list-ul {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 0 5px 0;
  padding: 0 25px;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; // For Firefox
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; // For Internet Explorer and Edge
}

// For WebKit browsers (Chrome, Safari, and Opera)
.pois-list-ul::-webkit-scrollbar {
  width: 8px;
}

.pois-list-ul::-webkit-scrollbar-thumb {
  background-color: #c1c3c5b4;
  border-radius: 10px;
}

.pois-list-ul::-webkit-scrollbar-track {
  background: transparent;
}

// For Firefox
.pois-list-ul {
  scrollbar-width: thin;
  scrollbar-color: #c1c3c5b4 transparent;
}

// For Internet Explorer and Edge
.pois-list-ul {
  -ms-overflow-style: none;
}

.pois-list-ul::-ms-scrollbar {
  display: none;
}

.poi-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 10px;
  margin: 5px 2px;
  border-radius: 5px;
  background-color: #586068;
}

.delete-list-item-btn {
  background: none;
  color: #fff;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  align-self: start;

  &:hover {
    color: #b31212;
  }
}

.poi-list-name {
  color: #e9e9e9;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.poi-list-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.pois-list-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  padding-bottom: calc(10px + env(safe-area-inset-bottom, 0));
}
