.marker {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.marker:hover {
  cursor: pointer;
}

.photo-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.poi-photo {
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}

.add-to-selected-btn {
  background-color: green;
  color: white;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.mapboxgl-popup-content {
  background-color: #576068;
  border-radius: 8px;
  color: #e9e9e9;
}

.mapboxgl-popup-close-button {
  color: #e9e9e9;
}