@import '~@fortawesome/fontawesome-free/css/all.min.css';

:root {
  --safe-area-inset-bottom: env(safe-area-inset-bottom, 0);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overscroll-behavior: none;
}

#root {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--safe-area-inset-bottom));
  max-height: 100dvh;
}

@supports (height: calc(100vh - env(safe-area-inset-bottom))) {
  #root {
    height: calc(100vh - env(safe-area-inset-bottom));
  }
}

@supports not (height: calc(100vh - env(safe-area-inset-bottom))) {
  #root {
    height: 100vh; /* Fallback for browsers that do not support env() */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}