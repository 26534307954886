@import '../../styles/variables.scss'; 

.safety-info-wrapper {
  display: block;
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width: none;
  width: 100%;
  height: 100%;
  color: $default-text-color;
}

.safety-info-inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0 5px;
}

.info_section_image {
  width: 100%;
  height: auto;
  max-height: 400px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.safety-info-country-name {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $default-text-color;
  padding-bottom: 5px;
}

.safety-info-body {
  padding: 10px 10px 30px 10px;
}

.safety-source-link {
  margin-left: 5px;
  color: $default-link-color;
  text-decoration: none;
}

.safety-info-message {
  color: $default-text-color;
}

.safety-img-source-link {
  margin-left: 3px;
  color: $default-link-color;
  text-decoration: none;
}

.photo-reference-info {
  font-size: 0.6em; 
}