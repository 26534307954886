@import '../../styles/variables.scss';

.header {
  z-index: 1;
  background-color: $default-header-bg-color;
  color: $default-text-color;
  display: flex;
  flex-direction: column;
}

.header-nav {
  z-index: 1;
  height: $nav-height;
  background-color: $default-header-bg-color;
  color: $default-text-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.header-spot-type-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a2d32;
}