@import '../../styles/variables.scss';

.spots-type-bar {
  z-index: 1;
  min-height: $nav-height;
  color: $default-text-color;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  background-color: #2a2d32;
}

.spot-type {
  width: 210px;
  height: 55px;
  display: flex;
  margin: 5px;
  color: $default-text-color;
  font-size: 1.2em;
  font-weight: 500;
  background-color: #0a6bcb;
  border-radius: 5px;
  padding: 0 5px;
}

.spot-type-wrapper {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spot-type-delete-button {
  padding-bottom: 20px;
  background: none;
  color: #e9e9e9;
  border: none;
  cursor: pointer;
  
  &:hover {
    color: #b31212;
  }
}
